<template>
  <div class="com-home-QuestionFour">
    <img
      class="img0"
      src="../../../../public/img/QuestionSolution/QuestionFour/底纹.png"
    />
    <img
      class="img1"
      src="../../../../public/img/QuestionSolution/QuestionFour/主文案@2x.png"
    />
    <img
      class="img2"
      src="../../../../public/img/QuestionSolution/QuestionFour/网页.png"
    />
    <img
      class="img3"
      src="../../../../public/img/QuestionSolution/QuestionFour/悬浮 饼状.png"
    />
    <img
      class="img4"
      src="../../../../public/img/QuestionSolution/QuestionFour/悬浮文案一@2x.png"
    />
    <img
      class="img5"
      src="../../../../public/img/QuestionSolution/QuestionFour/悬浮文案二@2x.png"
    />
    <img
      class="img6"
      src="../../../../public/img/QuestionSolution/QuestionFour/悬浮文案三@2x.png"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.$el.style.visibility = "visible";
      this.api.animateCSS(this.$el.querySelector(".img1"), "fadeInUp");
      this.api.animateCSS(this.$el.querySelector(".img2"), "fadeInUp");
      setTimeout(() => {
        const img3 = this.$el.querySelector(".img3");
        img3.style.display = "block";
        this.api.animateCSS(this.$el.querySelector(".img3"), "fadeInUp");
      }, 500);
      setTimeout(() => {
        const img4 = this.$el.querySelector(".img4");
        img4.style.display = "block";
        this.api.animateCSS(this.$el.querySelector(".img4"), "fadeInUp");
      }, 900);
      setTimeout(() => {
        const img5 = this.$el.querySelector(".img5");
        img5.style.display = "block";
        this.api.animateCSS(this.$el.querySelector(".img5"), "fadeInUp");
      }, 1100);
      setTimeout(() => {
        const img6 = this.$el.querySelector(".img6");
        img6.style.display = "block";
        this.api.animateCSS(this.$el.querySelector(".img6"), "fadeInUp");
      }, 1300);
    }, 100);
  },
};
</script>
<style lang="less" scoped>
.com-home-QuestionFour {
  .img0 {
    margin-left: 100px;
    margin-top: 100px;
  }
  .img1 {
    width: 560px;
    margin-left: 160px;
    margin-top: 10px;
  }
  .img2 {
    margin-left: 170px;
    margin-top: 170px;
  }
  .img3 {
    display: none;
    margin-left: 550px;
    margin-top: 135px;
  }
  .img4 {
    width: 266px;
    display: none;
    margin-left: 55px;
    margin-top: 250px;
  }
  .img5 {
    width: 266px;
    display: none;
    margin-left: 488px;
    margin-top: 320px;
  }
  .img6 {
    width: 319px;
    display: none;
    margin-left: 120px;
    margin-top: 385px;
  }
}
</style>
